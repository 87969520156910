@use 'global' as *;
.container {
  box-sizing: border-box;
  margin: 0;
  max-width: var(--global-max-width);
  white-space: pre-wrap;

  h1 {
    margin: 0 0 20px;
    text-wrap: balance;
  }

  h2 {
    margin-bottom: 20px;
    text-wrap: balance;
  }

  h3 {
    margin-bottom: 12px;
  }

  h4 {
    margin-bottom: 14px;
  }

  h5 {
    margin-bottom: 10px;
  }

  h1 a {
    color: var(--color-off-black);

    @include hover {
      color: var(--color-midnight);
    }
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;
    }
  }

  li {
    text-align: left;

    p {
      margin-left: 0.2rem;
    }
  }

  table {
    background: var(--color-white);
    border: 1px solid var(--color-slate);
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;

    tr {
      border-bottom: 1px solid var(--color-slate);
      display: flex;
      flex: 1;
      flex-direction: row;

      &:last-of-type {
        border-bottom: none;
      }

      p {
        padding: 0.5rem;
      }
    }

    th {
      background: var(--color-cement);
      flex: 1;
      text-align: left;
    }

    td {
      border-right: 1px solid var(--color-slate);
      flex: 1;

      &:last-of-type {
        border-right: none;
      }
    }
  }
}

.content_block {
  display: flex;
  flex-direction: column;
}

// ---------------------------------------------------------

.font_theme_light {
  color: var(--color-white);
}

.font_theme_dark {
  color: var(--color-off-black);
}

// ---------------------------------------------------------

.image_container {
  margin: 1rem auto;
}

.spread {
  &:not(:last-of-type) {
    display: inline-block;
    margin: 0 1rem 1rem 0;
  }
}

a {
  &.bold {
    font-family: var(--font-family-headings);
  }

  &.italic {
    font-family: var(--font-family-base-italic) !important;
  }
}

.callout {
  background: var(--color-cloud);
  border-radius: var(--global-border-radius);
  float: right;
  margin-bottom: 1rem;
  padding: 2rem;

  @include media($medium) {
    margin-left: 3rem;
    width: clamp(300px, 400px, 50%);
  }
}

.is_rounded,
.has_bg_color {
  padding: 1.5rem;

  @include media($small-highest) {
    padding: 2rem 2.5rem;
  }
}

.is_rounded {
  border-radius: 10px;

  img,
  iframe {
    border-radius: 15px;
  }
}
